export default() => {

  function expandMenu() {
    const expandTriggers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-behavior="expand-menu-trigger"]');

    expandTriggers.forEach(trigger => {
      if (trigger.getAttribute('aria-expanded') === 'false') {

      }
      const menuId = trigger.getAttribute('aria-controls'),
        wrapperId = trigger.getAttribute('aria-wrapper'),
        menu = document.getElementById(menuId),
        wrapper = document.getElementById(wrapperId);

      const closeMenu = () => {
        trigger.setAttribute('aria-expanded', 'false');
        wrapper.classList.remove('menu-open');
        menu.classList.remove('open');
      };

      trigger.addEventListener('click', () => {
        const closing = menu.classList.contains('open');

        //when opening the menu, add an event to close when losing focus
        if (closing) {
          menu.removeEventListener('focusout', closeMenu);
        } else {
          menu.addEventListener('focusout', closeMenu);
        }

        const toggleMenu = () => {
          menu.classList.toggle('open');
          trigger.setAttribute('aria-expanded', closing ? 'false' : 'true');
          wrapper.classList.toggle('menu-open');
        };

        toggleMenu();
      })
    })
  }

  document.addEventListener('DOMContentLoaded', () => {
    expandMenu();
  });
}
