import search from './search/search';
import setLinkTarget from './behavior/set-link-target';
import userTimings from '../user-timings';
import prebid from './analytics/prebid';
import setHeaderWaypoint from './set-header-waypoint/set-header-waypoint';
import expandableMenu from './expandable-menu/expandable-menu';
import expandMenu from './expandable-menu/expand-menu';
import toggleComments from './comments/toggle-comments';
import initMembershipsFeatures from './memberships/init-memberships-features';
import emailOnlyForms from './form-submit/email-only-forms';
import newsletterSplash from './form-submit/newsletter-splash';
import partnerOptin from './form-submit/partner-optin';
import eventRegistration from './form-submit/event-registration';
import recentQuestions from './recent-questions/recent-questions';
import recordSelectorUsage from './analytics/record-selector-usage';
import emblaCarousel from './carousel/embla-carousel';
import imageZoom from "./article-gallery/image-zoom";
import accountPanel from './account-panel/account-panel';
import imageCarousel from "./article-gallery/image-carousel";

search();
setLinkTarget();
userTimings();
expandableMenu();
expandMenu();
toggleComments();
recentQuestions();
initMembershipsFeatures();
emailOnlyForms();
newsletterSplash();
partnerOptin();
emblaCarousel();
eventRegistration();
recordSelectorUsage();
// special case for regular article template since the comments can move based on viewport
imageZoom('.js-image-zoom-wrapper');
imageZoom('.article__wrap.question');
imageZoom('.article--guide');
imageCarousel();
accountPanel();

document.addEventListener('DOMContentLoaded', () => {
  prebid();
  setHeaderWaypoint();
});
