import $ from 'jquery';

export default () => {
  $(function(){
    const $wrapper: HTMLElement = document.querySelector('.module--recent-qa__content');

    // module that appears on home page and single layout
    const recentQuestions = {
      _wrapper: $('.module--recent-qa__content'),
      init: function() {
        this.routes();
      },
      render: function(data) {
        const $wrapper = this._wrapper;

        if(typeof data.items !== 'undefined') {
          if(data.items.length) {
            const listTemplate = _.template($('#recent-qa').html());

            $wrapper.html(listTemplate(data));

          }

        }
      },
      request: function(query, callback) {
        $.ajax({
          type:'GET',
          url: '/wp/wp-admin/admin-ajax.php',
          data: query,
          dataType:'json',
          success: callback,
          error: function(request, status, error) {
            const errorTemplate = _.template($('#forum-results-error').html());

            $('.module--recent-qa__content').replaceWith(errorTemplate());
            console.log(status);
            console.log(error);
          },
          timeout: 5000
        });
      },
      setContent: function(count) {
        let request = 'action=recent_question_replies',
          limit = 10,
          that = this;

        if(typeof count !== 'undefined') {
          limit = parseInt(count, 10) || 10;
        }

        request += '&limit=' + limit;

        that.request(request, function(res) {
          that.render({items: res});
        });
      }
    };

    if($wrapper) {
      if('IntersectionObserver' in window) {
        const options = {
          root: null,
          rootMargin: '500px 0px 500px 0px',
          threshold: 0
        };

        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const observedItem = entry.target;
              recentQuestions.setContent(6);
              observer.unobserve(observedItem);
            }
          });
        }, options);

        observer.observe($wrapper);

      } else {
        recentQuestions.setContent(6);
      }
    }

  });
}
