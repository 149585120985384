export default () => {

  function handleExpandableMenu() {
    const $body: JQuery = $('body'),
      $html: JQuery = $('html'),
      $expandableMenu: JQuery = $('[data-behavior="expandable-menu"]'),
      $expandableTrigger: JQuery = $('[data-behavior="expandable-menu-trigger"]');

    $body.on('focus','[data-behavior="expandable-menu"] a',function(e) {
      $body.off('focus.moremenu'); // clean up previous event handlers if any

      const $theLink = $(e.target),
        $theDropDown: JQuery = $(e.target).parent().parent(),
        $container: JQuery = $theDropDown.parent(),
        $theTrigger: JQuery = $theDropDown.closest('[data-behavior="expandable-menu-trigger"]');

      $theTrigger.attr("aria-expanded","true");
      $container.addClass('menu-open');
      $theDropDown.addClass('open');

      $theLink.on('focusout.moremenu', function() {
        $theTrigger.attr("aria-expanded","false");
        $container.removeClass('menu-open');
        $theDropDown.removeClass('open');
        $theLink.off('focusout.moremenu');
      })
    });

    $body.on('click', '[data-behavior="expandable-menu-trigger"]', function (e) {
      e.preventDefault();
      $body.off('click.moremenu'); // clean up previous event handlers if any

      const $container: JQuery = $(e.target).parent(),
        $theTrigger: JQuery = $(e.target),
        $theDropDown: JQuery = $theTrigger.next('ul'),
        $moreItems: JQuery = $theDropDown.find('a');

      if ($theTrigger.attr('aria-expanded') === 'false') {
        $expandableTrigger.attr("aria-expanded","false");
        $theTrigger.attr("aria-expanded","true");
      } else {
        $theTrigger.attr("aria-expanded","false");
      }

      if ($container.hasClass('menu-open')) {
        $container.removeClass('menu-open');
      } else {
        $('.menu-open').removeClass('menu-open');
        $container.addClass('menu-open');
      }

      // only allow 1 expandable menu to be open at a time
      if ($expandableMenu.hasClass('open') && !$theDropDown.hasClass('open')) {
        $expandableMenu.removeClass('open');
      }

      if (!$theDropDown.hasClass('open')) {
        $theDropDown.addClass('open');
        $html.trigger('more-list-open');
      }

      const closeMenu = () => {
        if($theDropDown.hasClass('open')) {
          $theTrigger.attr("aria-expanded","false");
          $container.removeClass('menu-open');
          $theDropDown.removeClass('open');
          $body.off('click.moremenu');
          $body.off('keydown.moremenu');
          $theTrigger.off('keydown.moremenu');
          $moreItems.last().off('keydown');
        }
      };

      const handleOutsideClick = (e) => {
        if (!$theDropDown.is(e.target) && $theDropDown.has(e.target).length === 0) {
          closeMenu();
        }
      };

      $body.on('click.moremenu', handleOutsideClick);
      $body.on('keydown.moremenu',function(e) {
        if ((e.key) === 'Escape' && $theTrigger.attr('aria-expanded') === 'true') {
          $theTrigger.focus();
          closeMenu();
        }
      });
      $theTrigger.on('keydown.moremenu',function(e) {
        if ((e.key) === 'Tab') {
          if (e.shiftKey && $theTrigger.attr('aria-expanded') === 'true') {
            closeMenu();
          }
          return true;
       }
      });
      $moreItems.last().on('keydown', function(e) {
        if (!e.shiftKey && e.key === 'Tab') {
          $theTrigger.attr("aria-expanded","false");
          $container.removeClass('menu-open');
          $theDropDown.removeClass('open');
        }
        return true;
     });
    });
  }

  document.addEventListener('DOMContentLoaded', () => {
    handleExpandableMenu();
  });
}
