import {EmblaCarouselType} from "embla-carousel";

export default (btn: HTMLButtonElement, embla: EmblaCarouselType, slides: NodeListOf<Element>) => {
  return () => {
    const activeSlide: number = embla.selectedScrollSnap();

    if(slides[activeSlide] && slides[activeSlide].querySelector('.article-gallery-modal__caption')) {
      btn.removeAttribute('disabled');
    } else {
      btn.setAttribute('disabled', 'disabled');
    }
  }
}
