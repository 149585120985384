import storageAvailable from './storage-available';

export default (key) => {
  if (storageAvailable('localStorage') && localStorage.getItem(key)) {
    return localStorage.getItem(key);
  } else {
    return Cookies.get(key);
  }

};
