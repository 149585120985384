export default () => {
  // add tracking for user defined performance metrics. send to GA at a reduced sample rate
  var trackPerformanceMeasurements = function () {

    if (Math.random() < 0.1) {
      window.addEventListener('load', function () {
        if (window.performance && typeof ga === 'function' && typeof performance.getEntriesByType === 'function') {

          var perfEntries = performance.getEntriesByType('measure');

          perfEntries.filter(function (entry) {
            return entry.name.indexOf('taunton') !== -1;
          }).forEach(function (entry) {
            window.dataLayer.push({
              event: 'performance',
              action: entry.name,
              section: 'user_timing',
              value: Math.round(entry.duration)
            });
          });
        }
      });

      // record entries potentially fire after the load event has fired (e.g. ads).
      if (typeof PerformanceObserver === 'function' && typeof PerformanceObserver.prototype.observe !== 'undefined') {
        var observer = new PerformanceObserver(function (list) {
          list.getEntries().forEach(function (entry) {
            if (entry.name.indexOf('taunton') !== -1) {
              window.dataLayer.push({
                event: 'performance',
                action: entry.name,
                section: 'user_timing',
                value: Math.round(entry.duration)
              });
            }
          });
        });
        observer.observe({entryTypes: ['measure']});
      }
    }
  };

  trackPerformanceMeasurements();

};
