import isImage from "./is-image";
import insertImageCaption from "./insert-image-caption";
import {SINGLE_CLASSNAME} from "./constants";

/**
 * zooms on a single image within the article body
 */
export default (wrapper: string) => {
  const modal: HTMLElement = document.querySelector('.article-gallery-modal');
  const $wrapper: HTMLElement = document.querySelector(wrapper);
  if(modal && $wrapper) {
    $wrapper.addEventListener('click', (e) => {
      const link: HTMLAnchorElement = (e.target as HTMLElement).closest('a');

      if (link) {
        const isImageLink: boolean = isImage(link.getAttribute('href'));
        const image: HTMLImageElement = link.querySelector('img');
        const figure: HTMLElement = link.closest('figure');
        if(isImageLink && image) {
          let dialog;
          const template: HTMLTemplateElement = document.querySelector('#tmpl-article-gallery-modal-single');
          const modalBody: Element = document.querySelector('.article-gallery-modal__body');
          modalBody.innerHTML = '';

          if(template && modalBody) {
            const modalContent: Node = template.content.cloneNode(true);
            modalBody.appendChild(modalContent);
          } else {
            return;
          }

          const imageContainer: Element = modal.querySelector('.article-gallery-modal__image');
          const modalImage: Node = image.cloneNode(true);
          (modalImage as HTMLImageElement).removeAttribute('class');
          (modalImage as HTMLImageElement).removeAttribute('id');
          if((modalImage as HTMLImageElement).hasAttribute('data-link')) {
            (modalImage as HTMLImageElement).setAttribute('src', (modalImage as HTMLImageElement).getAttribute('data-link'));
            (modalImage as HTMLImageElement).removeAttribute('srcset');
          }
          (modalImage as HTMLImageElement).setAttribute('sizes', '100vw');
          e.preventDefault();
          imageContainer.appendChild(modalImage);

          insertImageCaption(figure, modal);

          import('./init').then(module => {
            dialog = module.default();
            if (dialog) {
              modal.classList.add(SINGLE_CLASSNAME);
              dialog.show();
            }
          });
        }
      }
    });
  }
}
