export default () => {
  const searchInputs = document.querySelectorAll('.search-results__results__searchfield input[name=s], input[name=s].embed-submit__textfield');
  let hasRun = false;

  const handleFocus = (e) => {
    import(/* webpackChunkName: 'typeahead' */'./typeahead')
      .then(({default: typeahead}) => {
        typeahead();
        e.target.focus(); // prevent focus loss when module load occurs
        // clean up event after the first run.
        if(hasRun === false) {
          [].forEach.call(searchInputs, (node) => {
            node.removeEventListener('focus', handleFocus);
          });
          hasRun = true;
        }
      });
  }

  // attach events to all search fields
  [].forEach.call (searchInputs, (node) => {
    node.addEventListener('focus', handleFocus);
  });
}
