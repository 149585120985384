import {CAPTION_CLASSNAME, CAPTION_STORAGE_KEY, CAPTION_VISIBLE_PROP} from "./constants";
import userGet from "../user-data/user-get";

export default (figure: HTMLElement, parent: HTMLElement) => {
  // insert caption
  if (figure) {
    const caption: HTMLElement = figure.querySelector('figcaption');
    const modalCaptionTarget: Element = parent.querySelector('.article-gallery-modal__text');
    if (caption && modalCaptionTarget) {
      parent.classList.add(CAPTION_CLASSNAME);
      const modalCaption: Node = caption.cloneNode(true);
      modalCaptionTarget.appendChild(modalCaption);
      if (userGet(CAPTION_STORAGE_KEY) === '1') {
        parent.setAttribute(CAPTION_VISIBLE_PROP, '1');
      }

      const div = document.createElement('div');
      const modalFigcaption = modalCaptionTarget.querySelector('figcaption');
      if(modalFigcaption) {
        modalFigcaption.replaceWith(div);
        div.innerHTML = modalFigcaption.innerHTML;
      }
    }
  }
}
