import EmblaCarousel from 'embla-carousel';
import { setupPrevNextBtns, disablePrevNextBtns } from './carousel-nav';
import setCarouselOptions from './set-carousel-options';

export default () => {
  const carousels = document.querySelectorAll('.embla');

  [].forEach.call(carousels, (wrap) => {
    const viewPort: HTMLElement = wrap.querySelector('.embla__viewport');
    const prevBtn = wrap.querySelector('.embla__button--prev');
    const nextBtn = wrap.querySelector('.embla__button--next');
    const options = setCarouselOptions(wrap);

    const embla = EmblaCarousel((viewPort as HTMLElement), (options as any));

    const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);

    setupPrevNextBtns(prevBtn, nextBtn, embla);

    embla.on('select', disablePrevAndNextBtns);
    embla.on('init', disablePrevAndNextBtns);
  });


}
