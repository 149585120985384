export default () => {
  if('IntersectionObserver' in window) {
    const $siteheader: HTMLElement =  document.querySelector('.siteheader'),
      $siteheaderMain: HTMLElement = document.querySelector('.siteheader--main'),
      $body: HTMLElement = document.querySelector('body');

    if($siteheader && $siteheaderMain && $body) {
      const navObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // in view
            $body.classList.remove('past-subnav-waypoint');
            $siteheader.classList.add('show');
            $siteheaderMain.classList.remove('offscreen');
            $siteheaderMain.classList.remove('scroll-waypoint');
          } else {
            // out of view
            $body.classList.add('past-subnav-waypoint');
            $siteheader.classList.remove('show');
            $siteheaderMain.classList.add('scroll-waypoint');
          }
        });
      }, {
        rootMargin: '0px 0px 200px 0px'
      });
      const navWaypoint: HTMLElement = document.getElementById('sticky-header-landmark');

      if(navWaypoint) {
        navObserver.observe(navWaypoint);
      }
    }
  }
}
