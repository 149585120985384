import $ from "jquery";

export default (): void => {
  $('.memberships__feature--label').each(function(index) {
    $(this).attr('style', '');
    var height = $(this).outerHeight(),
      item_index = index;

    $('.memberships__feature-list').each(function() {
      $(this).find('> li').eq(item_index).css({'height': height});
    });

  });

}
