// this is the for the memberships table at /memberships
import $ from 'jquery';
import membershipsCellHeights from './set-cell-heights';
import membershipsRowHeights from './set-row-heights';

export default function(): void {
  document.addEventListener('DOMContentLoaded', () => {
    membershipsCellHeights( $('.memberships__card__top') );
    membershipsRowHeights();

    // line row heights back up when the window is resized
    $(window).on('resize', _.debounce(function(){
      membershipsCellHeights( $('.memberships__card__top') );
      membershipsRowHeights();
    }, 500));
  });
}
