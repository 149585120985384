import codeUsage from "./code-usage";

const marketingJs = () => {
  // this code is for stuff in marketingJS. See todo comment for removal
  const enl = document.querySelector('#enewsletter-signup');
  const submit = document.querySelector('#submit_btn');
  const form = document.querySelector('#eletter_form');

  if(enl || submit || form) {
    codeUsage('#enewsletter', 'selector exists');
  }
};

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    marketingJs();
  });
}
