import $ from "jquery";

export default (items: JQuery): void => {
  let tallest = 0;

  items.each(function() {
    let item_height = $(this).outerHeight();
    if( item_height > tallest ) {
      tallest = item_height;
    }
  });

  items.css({
    'height': tallest
  });

}
