const IMAGE_ENDPOINTS = [
  'd4c5gb8slvq7w.cloudfront.net',
  'imgsvc.finegardening.com',
  'imgsvc.finehomebuilding.com',
  'imgsvc.finewoodworking.com',
  'imgsvc.greenbuildingadvisor.com',
  'imgsvc.threadsmagazine.com',
];
const IMAGE_EXTENSIONS = /\.(jpg|jpeg|png|webp|avif|gif)$/i;

export default (url: string): boolean => {
  let path = url.split('?')[0];
  let isImage = false;

  if(IMAGE_EXTENSIONS.test(path)) {
    return true;
  }

  try {
    const urlObj = new URL(url);
    isImage = IMAGE_ENDPOINTS.includes(urlObj.hostname);
  } catch (e) {
    isImage = false;
  }

  return isImage;
}

