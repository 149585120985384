export default (carouselWrap: HTMLElement) => {
  let smallOptions = {slidesToScroll: 1}; // override with data-carousel-sm
  let medOptions = {slidesToScroll: 3}; // override with data-carousel-md
  let largeOptions = {slidesToScroll: 4}; // override with data-carousel-lg

  let props = ['data-carousel-sm', 'data-carousel-md', 'data-carousel-lg'];
  props.forEach(prop => {
    if(carouselWrap.hasAttribute(prop)) {
      let breakpointOptions = null;
      if(carouselWrap.getAttribute(prop) === 'off') {
        breakpointOptions = {active: false};
      } else if(parseInt(carouselWrap.getAttribute(prop), 10)) {
        breakpointOptions = {slidesToScroll: parseInt(carouselWrap.getAttribute(prop), 10)};
      }

      if(breakpointOptions) {
        if (prop === 'data-carousel-sm') {
          smallOptions = breakpointOptions;
        } else if (prop === 'data-carousel-md') {
          medOptions = breakpointOptions;
        } else if (prop === 'data-carousel-lg') {
          largeOptions = breakpointOptions;
        }
      }
    }
  });

  return {
    align: 'start',
    ...smallOptions,
    skipSnaps: false,
    breakpoints: {
      '(min-width: 501px)': medOptions,
      '(min-width: 851px)': largeOptions
    }
  };
}
