import EmblaCarousel from 'embla-carousel';
import { setupPrevNextBtns, disablePrevNextBtns } from '../carousel/carousel-nav';
import setCarouselOptions from '../carousel/set-carousel-options';
import {CAPTION_CLASSNAME, CAPTION_STORAGE_KEY, CAPTION_VISIBLE_PROP, SINGLE_CLASSNAME} from "./constants";
import userGet from "../user-data/user-get";
import disableCaptionToggleBtn from "./disableCaptionToggleBtn";

/**
 * Launches an image gallery as defined attached to the article as meta.
 */
export default () => {
  const modal: HTMLElement = document.querySelector('.article-gallery-modal');

  if(modal) {
    let embla;
    const modalBody: Element = document.querySelector('.article-gallery-modal__body');
    document.addEventListener('click', (e) => {
      if ((e.target as HTMLElement).closest('.js-carousel-trigger')) {
        let dialog;
        e.preventDefault();
        const template: HTMLTemplateElement = document.querySelector('#tmpl-article-gallery-modal-carousel');
        if(modalBody && template) {
          modalBody.innerHTML = '';
          const carouselTemplate = template.content.cloneNode(true);
          modalBody.appendChild(carouselTemplate);
          modal.classList.remove(SINGLE_CLASSNAME);

          if(modalBody.querySelector('.article-gallery-modal__caption')) {
            modal.classList.add(CAPTION_CLASSNAME);
          }

          if(userGet(CAPTION_STORAGE_KEY) === '1') {
            modal.setAttribute(CAPTION_VISIBLE_PROP, '1');
          }

          import('./init').then(module => {
            dialog = module.default();
            return dialog;
          }).then(dialog => {
            dialog.show();
          });
        }
      }
    });

    modal.addEventListener('transitionend', (e) => {
      // pick one transitioned property so this doesn't fire multiple times
      if(e.propertyName === 'opacity') {
        if (!modal.hasAttribute('aria-hidden') && modalBody) {
          const carousel: HTMLElement = modalBody.querySelector('.embla');

          if(carousel) {
            const captionToggleBtn: HTMLButtonElement = modal.querySelector('.article-gallery-modal__toggle');
            const slides: NodeListOf<Element> = modal.querySelectorAll('.embla__slide');

            const viewPort: HTMLElement = modal.querySelector('.embla__viewport');
            const prevBtn: Element = modal.querySelector('.embla__button--prev');
            const nextBtn: Element = modal.querySelector('.embla__button--next');
            const options = setCarouselOptions(carousel);

            embla = EmblaCarousel((viewPort as HTMLElement), (options as any));

            const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);
            const disableCaptionBtn = disableCaptionToggleBtn(captionToggleBtn, embla, slides);

            setupPrevNextBtns(prevBtn, nextBtn, embla);

            embla.on('select', disablePrevAndNextBtns);
            embla.on('select', disableCaptionBtn);
            embla.on('init', disablePrevAndNextBtns);
            embla.on('init', disableCaptionBtn);
          }
        } else if (embla && typeof embla.destroy === 'function') {
          embla.destroy();
        }
      }
    });
  }
}

